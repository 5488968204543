import React from "react";
import styled from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 50px;
  text-align: justify;

  @media (max-width: 1024px) {
    padding: 2rem;
  }

  @media (max-width: 512px) {
    text-align: left;
  }
`;

const AboutPage = () => {
  return (
    <Layout>
      <SEO title="Xabadu" keywords={[`gatsby`, `application`, `react`]} />
      <PageWrapper>
        <h1>Hello, I'm Fernando <span role="img" aria-label="two fingers peace emoji">✌️</span></h1>
        <p>
          The stylin', profilin', (sometimes) bike riding, UI buildin', Web
          codin', community leadin', son of a gun!. I currently work as a Senior
          Software Engineer at Square <span role="img" aria-label="square logo">🔲</span>, doing JavaScript and Web related
          things.
        </p>
        <p>
          On my free time, I <span role="img" aria-label="heart emoji">❤️</span> working with OSS communities. I'm currently
          organizing and leading{" "}
          <a href="https://nodeschool.io/sanfrancisco">NodeSchool SF</a>, a
          community with monthly meetups dedicated to helping people learn
          JavaScript in a low stress, encouraging environment.
        </p>
        <p>
          I strongly believe in building communities that are inclusive,
          welcoming and that encourage people to know that technology is fun,
          for everyone and that we, as developers, have the opportunity to use
          our powers for good by building things that can make people's lives
          better.
        </p>
        <p>
          Outside of tech, I spend a ton of time talking about comics, ranting
          about wrestling or probably baking. (I make some mean <span role="img" aria-label="cookie emoji">🍪</span> - guaranteed).
        </p>
        <h3>Wanna say hi? <span role="img" aria-label="wave hand emoji">👋</span></h3>
        <p>You can find me in any of these places:</p>
        <ul>
          <li>
            Twitter:{" "}
            <a href="https://twitter.com/xabadu" className="regular-links">
              @xabadu
            </a>
          </li>
          <li>
            GitHub:{" "}
            <a href="https://github.com/Xabadu" className="regular-links">
              @Xabadu
            </a>
          </li>
          <li>
            Dev.to:{" "}
            <a href="https://dev.to/Xabadu" className="regular-links">
              @Xabadu
            </a>
          </li>
          <li>
            Medium:{" "}
            <a href="https://medium.com/@Xabadu" className="regular-links">
              @Xabadu
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/fernandolarranaga/"
              className="regular-links"
            >
              LinkedIn
            </a>
          </li>
        </ul>
      </PageWrapper>
    </Layout>
  );
};

export default AboutPage;
